<template>
  <div>
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div class="px-5">
      <v-row no-gutters>
        <v-col cols="12" md="10">
          <div class="mt-2 txt_search">
            <v-text-field
              v-model="find"
              hide-details
              solo
              flat
              background-color="#CFE1E1"
              class="rounded-lg"
              placeholder="Search for people"
              prepend-inner-icon="mdi-magnify"
              @keyup="fetchData"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="2" class="d-flex justify-end">
          <div class="mt-2 d-flex align-center">
            <h4 class="mr-2">Show</h4>
            <v-menu
              v-model="menuLimit"
              elevation="0"
              transition="slide-y-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  min-width="60px"
                  max-width="60px"
                  outlined
                  depressed
                  class="text-capitalize"
                  v-bind="attrs"
                  v-on="on"
                >
                  <p class="ma-0 default_txt">{{ limit }}</p>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card class="popup_radius" outlined elevation="0">
                <v-list>
                  <v-list-item link @click="changeLimit(10)">
                    <v-list-item-title>10</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(50)">
                    <v-list-item-title>50</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="changeLimit(100)">
                    <v-list-item-title>100</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-4" align="center">
        <v-col cols="12" md="4" class="py-2">
          <div class="d-flex align-center">
            <bullet :title="'List Event'" class="mr-2" />
          </div>
        </v-col>
        <v-col cols="12" md="8">
          <v-row no-gutters align="center" justify="end">
            <div
              class="col_tollbar"
              v-if="
                role != 'SUPERADMIN' &&
                role != 'ACCOUNTOFFICER' &&
                role != 'USER' &&
                role != 'ASSOCIATE'
              "
            >
              <v-btn
                depressed
                class="text-capitalize btn_tollbar rounded-lg bg_color1"
                dark
                @click="createData"
              >
                <v-icon> mdi-plus</v-icon> Tambah
              </v-btn>
            </div>
            <div class="col_tollbar">
              <v-menu
                :close-on-content-click="false"
                v-model="sort_menu"
                transition="slide-y-transition"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    dark
                    color="#CFE1E1"
                    class="text-capitalize btn_tollbar rounded-lg"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="#106967"> {{ icon_sort }}</v-icon>
                    <h4 class="ml-2" style="color: #106967">Sort</h4>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <div class="d-flex align-center">
                      <v-btn
                        @click="
                          changedesc(
                            icon_sort == 'mdi-sort-ascending'
                              ? 'mdi-sort-descending'
                              : 'mdi-sort-ascending'
                          )
                        "
                        block
                        class="text-capitalize d-flex justify-start"
                        text
                        style="flex-grow: 1"
                      >
                        <v-icon color="#106967">{{ icon_sort }}</v-icon>
                        <h4 style="color: #106967">Sort</h4>
                      </v-btn>
                    </div>
                    <v-btn @click="sort_menu = false" icon fab x-small>
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-radio-group
                      v-model="sort"
                      hide-details
                      class="ma-0"
                      dense
                      @change="fetchData"
                    >
                      <v-radio
                        label="Terbaru"
                        color="#106967"
                        value="created_at"
                      ></v-radio>
                      <v-radio
                        label="Tipe"
                        color="#106967"
                        value="tipe"
                      ></v-radio>
                      <v-radio
                        label="Default"
                        color="#106967"
                        value="id"
                      ></v-radio>
                    </v-radio-group>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div class="col_tollbar">
              <v-menu
                :close-on-content-click="false"
                v-model="filter_menu"
                transition="slide-y-transition"
                bottom
                min-width="290px"
                max-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    dark
                    color="#CFE1E1"
                    class="text-capitalize btn_tollbar rounded-lg"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="#106967" class="mr-2"> mdi-filter</v-icon>
                    <h4 style="color: #106967">Filter</h4>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <div
                      class="d-flex align-center"
                      style="flex-grow: 1; color: #106967"
                    >
                      <v-icon color="#106967" class="mr-2">mdi-filter</v-icon>
                      Filter
                    </div>
                    <v-btn @click="filter_menu = false" icon fab x-small>
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </v-list-item>
                  <v-list-item>
                    <v-expansion-panels accordion flat>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <h4>Tipe</h4>
                        </v-expansion-panel-header>
                        <v-divider class="mb-3"></v-divider>
                        <v-expansion-panel-content>
                          <v-radio-group
                            v-model="filter"
                            hide-details
                            class="ma-0"
                            dense
                            @change="fetchData"
                          >
                            <v-radio
                              label="Individu"
                              color="#106967"
                              value="individu"
                            ></v-radio>
                            <v-radio
                              label="Project"
                              color="#106967"
                              value="project"
                            ></v-radio>
                          </v-radio-group>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-list-item>
                  <v-list-item>
                    <v-expansion-panels accordion flat>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <h4>Layanan</h4>
                        </v-expansion-panel-header>
                        <v-divider class="mb-3"></v-divider>
                        <v-expansion-panel-content>
                          <v-radio-group
                            v-model="filter"
                            hide-details
                            class="ma-0"
                            dense
                            row
                            @change="fetchData"
                          >
                            <v-radio
                              v-for="(item, n) in dataLayanan"
                              :key="n"
                              class="my-1"
                              :label="item.layanan"
                              color="#106967"
                              :value="item.id"
                            ></v-radio>
                          </v-radio-group>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-list-item>
                  <v-list-item>
                    <div class="full_w" style="cursor: pointer">
                      <div @click="changeFilterDefault" class="px-6 py-3">
                        <h4>Default</h4>
                      </div>
                      <v-divider class="mb-3"></v-divider>
                    </div>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div>
      <v-skeleton-loader
        class="pa-2 elevation-0"
        type="table-tbody"
        v-if="loading"
      ></v-skeleton-loader>
      <div v-if="!loading && dataSet">
        <v-data-table
          :headers="headers"
          :items="dataSet"
          hide-default-footer
          :page.sync="page"
          :items-per-page="limit"
          :single-select="false"
          class="elevation-0"
        >
          <template v-slot:[`item.tipe`]="{ item }">
            <div class="d-flex align-center text-capitalize">
              {{ item.tipe }}
            </div>
          </template>
          <template v-slot:[`item.customer`]="{ item }">
            <div class="d-flex align-center text-capitalize">
              {{ item.customer }}
            </div>
          </template>
          <template v-slot:[`item.tujuan_event`]="{ item }">
            <div class="d-flex align-center text-capitalize">
              {{ item.tujuan_event }}
            </div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <!-- <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    depressed
                    class="mr-2"
                    @click="updateData(item)"
                  >
                    <v-icon dark small> mdi-pencil </v-icon>
                  </v-btn>
                </template>
                <span>Edit Event</span>
              </v-tooltip> -->
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    depressed
                    :to="`/admin/mo/event/detail/${item.id}`"
                  >
                    <v-icon dark small> mdi-eye </v-icon>
                  </v-btn></template
                >
                <span>Detail Event</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    depressed
                    color="red"
                    @click="deleteData(item)"
                  >
                    <v-icon dark small> mdi-delete </v-icon>
                  </v-btn></template
                >
                <span>Hapus Event</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
        <div class="text-center pa-3">
          <v-pagination
            color="#052633"
            v-model="page"
            @input="fetchData"
            :length="last_page"
          ></v-pagination>
        </div>
      </div>

      <div class="pa-2">
        <dialog-small
          :d_small="d_small"
          @refetch="fetchData"
          @close="d_small = false"
          :inventory="inventory"
          :type="d_type"
          :title="d_title"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  components: { bullet, NavbarProfile },
  name: "listEvent",
  computed: {
    ...mapState({
      role: (state) => state.role,
    }),
  },
  data() {
    return {
      loading: true,
      icon_sort: "mdi-sort-ascending",
      sort_menu: false,
      sort: "",
      find: "",
      filter_menu: false,
      filter: "",
      page: 1,
      limit: 10,
      total: null,
      last_page: null,
      menuLimit: false,
      dataSet: null,
      d_small: false,
      d_type: "",
      d_title: "",
      inventory: "",
      dataLayanan: [],
      line: "",
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "List Event",
          disabled: true,
          href: "#",
        },
      ],
      headers: [
        { text: "Klien", value: "customer", width: "25%" },
        { text: "Tipe", value: "tipe" },
        {
          text: "Tujuan",
          value: "tujuan_event",
          sortable: false,
          width: "25%",
        },
        { text: "Layanan", value: "layanan" },
        { text: "Action", value: "actions", sortable: false },
      ],
    };
  },
  mounted() {
    this.fetchDataLayanan();
    this.fetchData();
  },
  methods: {
    changeFilterDefault() {
      this.filter = "";
      this.fetchData();
    },
    fetchDataLayanan() {
      this.loading = true;
      let data = {
        path: `sysadmin/event/daftar-layanan`,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          this.dataLayanan = data;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    createData() {
      localStorage.setItem("creating", true);
      this.$router.push("/admin/mo/event/create");
    },
    fetchData() {
      this.loading = true;
      let filter =
        this.filter == "individu" || this.filter == "project"
          ? "tipe"
          : "layanan_id";
      let path = `sysadmin/event?page[size]=${this.limit}&page[number]=${this.page}&sort=${this.sort}&filter[${filter}]=${this.filter}`;
      if (this.sort != "") {
        path = `sysadmin/event?page[size]=${this.limit}&page[number]=${
          this.page
        }&sort=${this.icon_sort == "mdi-sort-ascending" ? "-" : "+"}${
          this.sort
        }&filter[${filter}]=${this.filter}`;
      }
      let data = {
        path: path,
      };
      this.$store
        .dispatch("client/getData", data)
        .then((data) => {
          this.dataSet = data.data;
          this.last_page = data.last_page;
          this.total_page = data.total_page;
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    changeSort(item) {
      this.sort = item;
      this.sort_menu = false;
      this.fetchData();
    },
    changedesc(item) {
      this.icon_sort = item;
      this.fetchData();
    },
    changeLimit(val) {
      this.limit = val;
      this.fetchData();
    },
    deleteData(item) {
      this.inventory = {
        data: item,
        path: `sysadmin/event/delete`,
        deleteType: "List Layanan Psiokotes",
      };
      this.d_type = "delete";
      this.d_title = "Hapus Layanan Psiokotes";
      this.d_small = true;
      this.line = true;
    },
    // updateData(item) {},
  },
};
</script>

<style scoped></style>
